import * as React from "react";
import { Link } from "gatsby";
import { Text } from "@chakra-ui/react";

const Layout = ({ location, title, slogan, ruc, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (isRootPath) {
    header = (
      <>
        <h1 className="main-heading">
          <Link to="/">{title}</Link>
        </h1>
        <Text
          as="h3"
          fontSize="18"
          mt="0.5"
          color="gray.500"
          mb="0"
          isTruncated
        >
          {slogan}
        </Text>
        <Text mt="0.5">RUC: {ruc}</Text>
      </>
    );
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    );
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer>
        © NorthVraem, Todos los derechos reservados {new Date().getFullYear()}
      </footer>
    </div>
  );
};

export default Layout;
